export const ADD_FILE = 'ADD_FILE'
export const DELETE_FILE = 'DELETE_FILE'
export const CHANGE_FILE_STATUS = 'CHANGE_FILE_STATUS'
export const RENAME_FILE = 'RENAME_FILE'

export const LOGOUT = 'LOGOUT'
export const STORE_USER = 'STORE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SAVE_AVATAR_URL = 'SAVE_AVATAR_URL'

export const INCREASE_USED_SPACE = 'INCREASE_USED_SPACE'
export const DECREASE_USED_SPACE = 'DECREASE_USED_SPACE'